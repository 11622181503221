<template>
  <v-form v-model="formValid" ref="registryFormTab">
    <v-subheader class="font-italic font-weight-light py-1">Generale</v-subheader>
    <v-row >
      <v-col>
        <v-text-field
          v-model="name"
          :rules="[max255CharRule, presenceRule]"
          dense
          filled
          label="Nome*"
          hide-details="auto"
          @input="updateObject"
          autofocus
          :disabled="disabled"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="description"
          :rules="[max255CharRule, presenceRule]"
          dense
          filled
          label="Descrizione*"
          hide-details="auto"
          :disabled="disabled"
          @input="updateObject"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
         <v-select
          v-model="localStatus"
          filled
          dense
          label="Stato Promozione*"
          :items="[
            {text: 'Attiva', value: 'active', subText: 'La promozione è attiva dal momento in cui esiste'},
            {text: 'Non attiva', value: 'notActive', subText: 'La promozione non è attiva e non viene considerata'},
            {text: 'Attiva nelle date', value: 'activeInDates', subText: 'La promozione è attiva solo nelle date specificate'},
          ]"
          hide-details="auto"
          @input="updateObject"
          :disabled="disabled"
          :rules="[presenceRule]"
        >
          <template v-slot:item="{item}">
            <div class="d-flex flex-column">
              <div class="font-weight-bold">{{item.text}}</div>
              <div class="text-caption">
                {{item.subText}}
              </div>
            </div>
          </template>
        </v-select>
      </v-col>
      <v-col>
        <div class="d-flex justify-center align-center">
          <v-text-field
            class="pr-2"
            v-model="barcode"
            dense
            filled
            readonly
            disabled
            label="Barcode generato"
            hide-details="auto"
          ></v-text-field>
          <v-btn
            text
            :disabled="!!barcode"
            @click="generateBarcode"
          >
            Genera
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="localStatus == 'activeInDates' || localStatus == 'active'">
      <v-col>
        <DateTimePicker
          filled
          v-model="activeFrom"
          label="Attiva dalla data"
          clearable
          :show-time="false"
          @input="updateObject"
          :disabled="disabled"
          date-picker-width="300px"
        ></DateTimePicker>
        <DateTimePicker
          v-if="localStatus == 'activeInDates'"
          filled
          v-model="activeTo"
          label="Attiva fino alla data"
          :range-mode="true"
          :start-range="activeFrom"
          :show-time="false"
          @input="updateObject"
          :disabled="disabled"
          date-picker-width="300px"
        ></DateTimePicker>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <div class="mt-5">Descrizione da stampare nello scontrino</div>
        <div
          contenteditable="true"
          :contentdisabled="disabled"
          class="editable-message mt-3" 
          ref="editableMessage"
          label="Descrizione da stampare nello scontrino"
          @input="calculateTextFromHtml"
        ></div>
        <div class="hidden-editable-message" ref="hiddenEditableMessage">
        </div>
        <div class="mt-2">
          <v-chip 
            v-for="placeholder in possiblePlaceholders"
            :key="placeholder.name"
            color="primary" 
            class="ma-1" 
            @click="addPlaceholder(placeholder.name, placeholder.text)"
            :disabled="disabled"
          >{{placeholder.text}}</v-chip>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-checkbox
          v-model="newCustomer"
          dense
          label="Promo per nuovo cliente"
          hide-details="auto"
          :disabled="disabled"
          @change="updateObject"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-subheader class="font-italic font-weight-light py-1 mt-7">Come il cliente la guadagna*</v-subheader>
    <v-row>
      <v-col>
        <ResponsiveCardSelector
          :items="gainTypeItems"
          v-model="gainType"
          item-key="name"
          card-max-height="200"
          card-min-height="200"
          card-max-width="200"
          card-min-width="200"
          justify-space-around
          align-center
          @input="() => {
            useTerms = undefined
            targetServices = []
            price = undefined
            updateObject()
          }"
          :colors="['#ada2e0']"
          :disabled="disabled"
          :colorOnSelected="true"
        >
          <template v-slot:item="{ item }">
            <div class="pa-2 d-flex flex-column justify-center align-center">
              <div class="mt-2 text-h6">{{ item.text }}</div>
              <div class="mt-2 text-caption text-center">{{ item.description }}</div>
              <div class=""></div>
              <v-icon class="ma-5" large>{{ item.icon }}</v-icon>
            </div>
          </template>
        </ResponsiveCardSelector>
      </v-col>
    </v-row>
    <template v-if="gainType == 'target'">
      <v-subheader class="font-italic font-weight-light py-1 mt-7">L'obiettivo che il cliente si deve guadagnare</v-subheader>
      <!--<v-row>
        <v-col cols="auto">
          <v-btn
            text
            class="mr-2 mt-2"
            @click="applyAll('target')"
          > Seleziona tutti i servizi
            <v-icon class="ml-2"> mdi-clipboard-list</v-icon>
          </v-btn>
        </v-col>
      </v-row>-->
      <v-row>
        <v-col>
          <GroupedSelectList
            :items="serviceTypesTarget"
            :allow-all-subcat="true"
            categories-identifier-key="id"
            items-text-key="name"
            categories-text-key="name"
            items-key="services"
            v-model="targetServices"
            :disabled="allServicesTarget || disabled"
            @input="updateObject"
          >
            <template v-slot:extra-actions="{ active, item }">
              <v-expand-transition>
                <v-text-field
                  :rules="[presenceRule]"
                  v-if="active && !allServicesTarget"
                  type="number"
                  dense
                  hide-details="auto"
                  filled
                  :min="0"
                  label="Quantità"
                  style="width: 150px"
                  v-model="targetServicesQuantity[item.id]"
                  @input="updateObject"
                ></v-text-field>
              </v-expand-transition>
            </template>
          </GroupedSelectList>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="gainType == 'price'">
      <v-row>
        <v-col>
          <v-text-field
            dense
            filled
            v-model="price"
            label="Prezzo della promozione"
            prepend-icon="mdi-currency-eur"
            @input="updateObject"
            type="number"
            :disabled="disabled"
            :error="!price"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-subheader class="font-italic font-weight-light py-1 mt-2">Selezionare la tipologia di pagamento</v-subheader>
      <v-row>
        <v-col>
          <v-radio-group
            v-model="paymentType"
            :rules="[presenceRule]"
            column
            :disabled="disabled"
            @input="updateObject"
          >
            <v-radio
              label="Pagamento in un unica soluzione"
              value="total"
            ></v-radio>
            <v-radio
              class="mt-2"
              label="Pagamento a rate"
              value="scheduled"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </template>
    <v-subheader class="font-italic font-weight-light py-1 mt-7">Cosa guadagna il cliente*</v-subheader>
    <v-row>
      <v-col>
        <ResponsiveCardSelector
          :items="itemGainedItems"
          v-model="itemGained"
          item-key="name"
          card-max-height="200"
          card-min-height="200"
          card-max-width="200"
          card-min-width="200"
          justify-space-around
          align-center
          @input="() => handleUseTermsOrItemGainedChange('itemGained')"
          :colors="['#ada2e0']"
          :disabled="disabled"
          :colorOnSelected="true"
        >
          <template v-slot:item="{ item }">
            <div class="pa-2 d-flex flex-column justify-center align-center">
              <div class="mt-2 text-h6">{{ item.text }}</div>
              <div class="mt-2 text-caption text-center">{{ item.description }}</div>
              <div class=""></div>
              <v-icon class="ma-5" large>{{ item.icon }}</v-icon>
            </div>
          </template>
        </ResponsiveCardSelector>
      </v-col>
    </v-row>
    <template v-if="itemGained == 'discount' || itemGained == 'servicesDiscounted'">
      <v-row>
        <v-col>
          <v-text-field
            :rules="[presenceRule, greaterThan0]"
            dense
            filled
            v-model="discount"
            label="Sconto"
            type="number"
            prepend-icon="mdi-percent"
            @input="updateObject"
            :disabled="disabled"
            :error="!discount"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-if="itemGained == 'amount'">
      <v-row>
        <v-col>
          <v-text-field
            :rules="[presenceRule]"
            dense
            filled
            v-model="amount"
            label="Importo"
            prepend-icon="mdi-currency-eur"
            type="number"
            @input="updateObject"
            :disabled="disabled"
            :error="!amount"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-else-if="itemGained == 'services' || itemGained == 'servicesDiscounted'">
      <v-row>
        <v-col cols="auto">
          <v-btn
            text
            class="mr-2 mt-2"
            :disabled="disabled"
            @click="applyAll('gained')"
          > Seleziona tutti i servizi
            <v-icon class="ml-2"> mdi-clipboard-list</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <GroupedSelectList
            :items="serviceTypesValid"
            :allow-all-subcat="true"
            categories-identifier-key="id"
            items-text-key="name"
            categories-text-key="name"
            items-key="services"
            v-model="validDiscountServices"
            :disabled="allServicesValid || disabled"
            @input="updateObject"
          >
            <template v-slot:extra-actions="{ active, item }">
              <v-expand-transition>
                <v-text-field
                  :rules="[presenceRule]"
                  v-if="active && !allServicesValid"
                  type="number"
                  dense
                  hide-details="auto"
                  filled
                  :min="0"
                  label="Quantità"
                  style="width: 150px"
                  v-model="validDiscountServicesQuantity[item.id]"
                  @input="updateObject"
                ></v-text-field>
              </v-expand-transition>
            </template>
          </GroupedSelectList>
        </v-col>
      </v-row>
    </template>
    <template v-if="itemGained == 'discount' || itemGained == 'amount'">
      <v-subheader class="font-italic font-weight-light py-1 mt-7">Valido solo per i prodotti</v-subheader>
      <v-row>
        <v-col cols="auto">
          <v-switch
            hide-details="auto"
            v-model="allItemsValid"
            label="Tutti i prodotti"
            class="ml-3"
            :disabled="disabled"
            @change="updateObject"
          ></v-switch>
        </v-col>
      </v-row>
      <v-subheader class="font-italic font-weight-light py-1 mt-7">Valido solo per i servizi</v-subheader>
      <v-row>
        <v-col cols="auto">
          <v-btn
            text
            class="mr-2 mt-2"
            :disabled="disabled"
            @click="applyAll('valid')"
          > Seleziona tutti i servizi
            <v-icon class="ml-2"> mdi-clipboard-list</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <GroupedSelectList
            :items="serviceTypesValid"
            :allow-all-subcat="true"
            categories-identifier-key="id"
            items-text-key="name"
            categories-text-key="name"
            items-key="services"
            :disabled="allServicesValid || disabled"
            v-model="validDiscountServices"
            @input="updateObject"
          >
            <template v-slot:extra-actions="{ active, item }">
              <v-expand-transition>
                <v-text-field
                  :rules="!serviceTypePromo ? [presenceRule] : []"
                  v-if="active && !allServicesValid"
                  type="number"
                  dense
                  hide-details="auto"
                  filled
                  :min="0"
                  label="Quantità"
                  style="width: 150px"
                  v-model="validDiscountServicesQuantity[item.id]"
                  @input="updateObject"
                ></v-text-field>
              </v-expand-transition>
            </template>
          </GroupedSelectList>
        </v-col>
      </v-row>
    </template>
    <v-subheader class="font-italic font-weight-light py-1 mt-7">Termini di utilizzo*</v-subheader>
    <v-row>
      <v-col>
        <ResponsiveCardSelector
          :items="filteredUseTermsItems"
          v-model="useTerms"
          item-key="name"
          card-max-height="200"
          card-min-height="200"
          card-max-width="200"
          card-min-width="200"
          justify-space-around
          align-center
          @input="() => handleUseTermsOrItemGainedChange('useTerms')"
          :colors="['#ada2e0']"
          :disabled="disabled"
          :colorOnSelected="true"
        >
          <template v-slot:item="{ item }">
            <div class="pa-2 d-flex flex-column justify-center align-center">
              <div class="mt-2 text-h6">{{ item.text }}</div>
              <div class="mt-2 text-caption text-center">{{ item.description }}</div>
              <div class=""></div>
              <v-icon class="ma-5" large>{{ item.icon }}</v-icon>
            </div>
          </template>
        </ResponsiveCardSelector>
      </v-col>
    </v-row>
    <template v-if="useTerms == 'range' || useTerms == 'oneShotRange' || useTerms == 'untillEndRange'">
      <v-row>
        <v-col>
          <v-select
            :rules="[presenceRule]"
            v-model="rangeMode"
            filled
            dense
            label="Range mode"
            :items="[
              {text: 'Date', value: 'dates', subText: 'La promozione dura per tutti dalle date specificate'},
              {text: 'Durata', value: 'duration', subText: 'La promozione ha una durata specifica a partire dall\'assegnazione'},
            ]"
            :hint="!!rangeMode ? rangeMode == 'dates' ? 
              'La promozione dura per tutti dalle date specificate' :
              'La promozione ha una durata specifica a partire dall\'assegnazione' : undefined"
            persistent-hint
            :error="!rangeMode"
            :disabled="disabled"
            @input="() => {scrollToBottom(); updateObject();}"
          >
            <template v-slot:item="{item}">
              <div class="d-flex flex-column">
                <div class="font-weight-bold">{{item.text}}</div>
                <div class="text-caption">
                  {{item.subText}}
                </div>
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="rangeMode == 'dates'">
        <v-col>
          <DateTimePicker
            filled
            v-model="startDate"
            label="Dalla data"
            clearable
            :show-time="false"
            date-picker-width="300px"
            :disabled="disabled"
            @input="updateObject"
          ></DateTimePicker>
        </v-col>
        <v-col>
          <DateTimePicker
            filled
            v-model="expirationDate"
            label="Alla data"
            :min="!!startDate ? new Date(startDate.getTime() + (24 * 360000)) : undefined"
            :show-time="false"
            date-picker-width="300px"
            :disabled="disabled"
            @input="updateObject"
          ></DateTimePicker>
        </v-col>
      </v-row>
      <v-row v-else-if="rangeMode == 'duration'">
        <v-col>
          <DurationPicker
            v-model="duration"
            days
            label="Durata (giorni)"
            placeholder="Infinita"
            persistent-placeholder
            :rules="[
              (value) => {
                return value <= 10000 || 'La durata deve essere minore di 10000'
              }
            ]"
            :disabled="disabled"
            @input="updateObject"
          ></DurationPicker>
        </v-col>
      </v-row>
    </template>

    <v-dialog
      v-model="warningDialog"
      persistent
    >
      <v-alert border="left" type="error" style="margin: 0">
        <div>{{warningMessage}}</div>
        <div class="mt-4 d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="warningDialog = false"
          >Chiudi</v-btn>
        </div>
      </v-alert>
    </v-dialog>
  </v-form>
</template>

<script>
import Vue from 'vue'
import DateTimePicker from '@/components/common/DateTimePicker.vue'
import promoForm from '@/services/promos/promo.form.js'
import DurationPicker from '@/components/common/DurationPicker.vue'
import GroupedSelectList from '@/components/common/GroupedSelectList.vue';
import serviceTypeService from '@/services/serviceTypes/serviceTypes.service.js';
import barcodeService from "@/services/barcode/barcode.service.js";
import ResponsiveCardSelector from "@/components/common/ResponsiveCardSelector.vue";
import StandardDialog from '@/components/common/StandardDialog.vue';

import promosService from '@/services/promos/promos.service.js'

import { cloneDeep } from "lodash"
import { v4 as uuidv4 } from "uuid";

export default {
  name: "GeneralFormTab",
  components: {
    DurationPicker,
    GroupedSelectList,
    ResponsiveCardSelector,
    DateTimePicker,
    StandardDialog
  },
  data: function() {
    return {
      id: undefined,
      name: undefined,
      description: undefined,
      localStatus: undefined,
      activeFrom: new Date(),
      activeTo: undefined,
      loadingPromo: false,
      formValid: false,
      allServicesTarget: false,
      allServicesTargetQuantity: undefined,
      targetServices: [],
      targetServicesQuantity: {},
      allServicesValid: false,
      allItemsValid: false,
      newCustomer: false,
      validServices: [],
      serviceTypesGained: [],
      serviceTypesValid: [],
      serviceTypesTarget: [],
      paymentType: undefined,
      selectedPlaceholders: [],
      allActive: false,
      possiblePlaceholders: [
        { name: 'customer.firstname', text: 'Nome Cliente' },
        { name: 'customer.lastname', text: 'Cognome Cliente' },
        { name: 'customer.fullname', text: 'Nome Completo Cliente' },
      ],
      max255CharRule: (v) => {
        if(v) {
          return v.length <= 255 || 'Al massimo 255 caratteri'
        } else {
          return true
        }
      },
      presenceRule: (v) => {
        return !!v || 'Il campo è obbligatorio'
      },
      gainType: undefined,
      gainTypeItems: [
        {
          name: 'target',
          text: 'A obiettivo',
          description: 'Il cliente deve raggiungere il determinato obiettivo per poterla guadagnare',
          icon: 'mdi-bullseye-arrow'
        },
        {
          name: 'simple',
          text: 'Semplice',
          description: 'La promozione viene assegnata da un operatore in cassa',
          icon: 'mdi-tag-outline'
        },
        {
          name: 'price',
          text: 'Costo',
          description: 'La promozione viene assegnata solo se il cliente è disposto a pagare l\'importo indicato',
          icon: 'mdi-tag-text-outline'
        },
      ],
      allServicesGained: false,
      allServicesGainedQuantity: undefined,
      itemGained: undefined,
      itemGainedItems: [
        {
          name: 'discount',
          text: 'Sconto',
          description: 'Uno sconto da poter applicare ai servizi in percentuale',
          icon: 'mdi-label-percent'
        },
        {
          name: 'amount',
          text: 'Importo',
          description: 'Un voucher da poter utilizzare per pagare in negozio',
          icon: 'mdi-cash'
        },
        {
          name: 'services',
          text: 'Servizi gratuiti',
          description: 'La possibilità di usufruire di servizi gratuiti',
          icon: 'mdi-chair-rolling'
        },
      ],
      price: undefined,
      discount: undefined,
      gainedServices: [],
      validDiscountServices: [],
      gainedServicesQuantity: {},
      validDiscountServicesQuantity: {},
      discount: undefined,
      amount: undefined,
      useTermsItems: [
        {
          name: 'oneShot',
          text: 'One Shot',
          description: 'Può essere utilizzata una sola volta',
          icon: 'mdi-clipboard-text-clock-outline'
        }, {
          name: 'oneShotRange',
          text: 'One Shot Range',
          description: 'Può essere utilizzata una sola volta ma nel range specificato',
          icon: 'mdi-store-clock'
        }, {
          name: 'range',
          text: 'Range',
          description: 'Può essere utilizzata infinite volte nel range specificato',
          icon: 'mdi-all-inclusive'
        }, {
          name: 'untillEndRange',
          text: 'Esaurimento Range',
          description: 'Può essere utilizzata fino a quando non esaurisce nel range specificato',
          icon: 'mdi-clock-start'
        }, {
          name: 'untillEnd',
          text: 'Esaurimento',
          description: 'Può essere utilizzata fino a quando non esaurisce',
          icon: 'mdi-clock-end'
        },
      ],
      useTerms: undefined,
      rangeMode: undefined,
      startDate: undefined,
      expirationDate: undefined,
      notes: '',
      savedRange: undefined,
      duration: undefined,
      localHtml: undefined,
      greaterThan0: (v) => {
        return (!isNaN(v) && v > 0 ) || "Importo non valido";
      },
      warningDialog: false,
      warningTitle: undefined,
      warningMessage: undefined,
      barcode: undefined,
      isInUse: false,
    };
  },
  props: {
    bus: {
      type: Object,
      default: function() {
        return new Vue()
      }
    },
    nameGiftCard: {
      type: String,
      default: "",
    },
    descriptionGiftCard: {
      type: String,
      default: "",
    },
    customerGiftCard: {
      type: String,
      default: "",
    },
    status: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  mounted: async function() {
    this.handleObjectChanges(promoForm.promo)

    if(this.nameGiftCard){
      this.name = this.nameGiftCard
    }

    if(this.descriptionGiftCard){
      this.description = this.descriptionGiftCard + "" + this.customerGiftCard
    }

    if(!!this.status) {
      this.localStatus = this.status
    }

    var self = this
    promoForm.on('update', function(data) {
      self.handleObjectChanges(data.promo)
    })

    if(this.bus)
      this.bus.$on('reset', this.reset)

    this.loadServiceTypes();
    this.$refs.registryFormTab.validate()
  },
  methods: {
    fields() {
      return [
        'id', 
        'name',
        'description', 
        'notes', 
        //'notesReceipt', 
        'gainType', 
        'price', 
        'itemGained', 
        'discount', 
        'amount',
        'useTerms',
        'rangeMode',
        'paymentType',
        'duration',
        'newCustomer',
        'allServicesTarget',
        'allServicesGained',
        'allServicesValid',
        'allItemsValid',
        'allServicesTargetQuantity',
        'allServicesGainedQuantity',
      ]
    },
/*     async disabled() {
      if (!!this.sharedPromo && !!this.sharedPromo.id) {
        let promoCustomerslist = await promosService.listPromoCustomerByPromoId(this.sharedPromo)
        return this.type == 'edit' && await promoCustomerslist.filter(el => el.usageProgress == 'beginning').length > 0
      }
      else return false

    }, */
    async generateBarcode() {
      let generatedUiid = uuidv4().toString().replaceAll("-", "").toUpperCase();
      let shortenUuid = generatedUiid.slice(0, 17)
      let barcodeFromDb = await barcodeService.getByCode("promos", shortenUuid)
      if (!!barcodeFromDb && Object.keys(barcodeFromDb).length > 0) {
        this.generateBarcode()
      } else {
        this.barcode = shortenUuid
        this.updateObject()
        if (this.type == "edit") {
          await promosService.update(this.sharedPromo)   
        }
      }
    },
    handleObjectChanges(newPromo) {
      let promo = newPromo
      const fields = this.fields()
      const newValKeys = Object.keys(promo)

      for(let i = 0; i < fields.length; i++) {
        const field = fields[i]
        if(newValKeys.includes(field) && promo[field] != this[field]) {
          this[field] = promo[field]
        }
      }

      if(!!promo.status) this.localStatus = promo.status
      if(!!promo.startDate) this.startDate = new Date(promo.startDate)
      if(!!promo.expirationDate) this.expirationDate = new Date(promo.expirationDate)
      if(!!promo.activeFrom) this.activeFrom = new Date(promo.activeFrom)
      if(!!promo.activeTo) this.activeTo = new Date(promo.activeTo)
      if(!!promo.barcode) this.barcode = !!promo.barcode.code ? promo.barcode.code : promo.barcode


      if(!!promo.gainedItems && promo.gainedItems.length != 0) {
        this.validDiscountServices = []
        this.validDiscountServicesQuantity = {}
        this.gainedServices = cloneDeep(promo.gainedItems.map(item => {
          this.validDiscountServices.push(item)       
          this.validDiscountServicesQuantity[item.serviceId] = item.quantity
          this.gainedServicesQuantity[item.serviceId] = item.quantity
          return {
            id: Number(item.serviceId),
          }
        }))

        for(const [key, value] of Object.entries(this.gainedServicesQuantity)) {
          if(!promo.gainedItems.find((gi) => gi.serviceId == key)) {
            delete this.gainedServicesQuantity[key]
          }
        }
      } else if(!!promo.gainedItems && promo.gainedItems.length == 0) {
        for(const [key, value] of Object.entries(this.gainedServicesQuantity)) {
          delete this.gainedServicesQuantity[key]
        }
      }

      if(this.gainedServicesQuantity !== undefined && this.gainedServicesQuantity !== null) {
        this.gainedServicesQuantity = cloneDeep(this.gainedServicesQuantity)
      }

      if(!!promo.validDiscountItems && promo.validDiscountItems.length != 0) {
        this.validDiscountServices = []
        this.validDiscountServicesQuantity = {}
        promo.validDiscountItems.map(item => {
          this.validDiscountServices.push(item)
          this.validDiscountServicesQuantity[item.id] = item.quantity
          return {
            id: Number(item.serviceId),
          }
        })
      }      

      if(!!promo.targetItems && promo.targetItems.length != 0) {
        this.targetServices = cloneDeep(promo.targetItems.map(item => {
          this.targetServicesQuantity[item.serviceId] = item.quantity
          return {
            id: Number(item.serviceId),
          }
        }))
      }

      if(!!promo.validItems && promo.validItems.length != 0) {
        this.validServices = cloneDeep(promo.validItems.map(item => {
          return {
            id: Number(item.serviceId),
          }
        }))
      }
    },
    loadServiceTypes() {
      serviceTypeService.list(undefined, undefined, {isBar: false}).then((response) => {
        this.serviceTypesGained = cloneDeep(response.rows)
        this.serviceTypesValid = cloneDeep(response.rows)
        this.serviceTypesTarget = cloneDeep(response.rows)
      })
    },
    handleChangeType() {
      this.discount = undefined
      this.amount = undefined
      this.updateObject()
    },
    updateObject() {
      promoForm.updateObject((this.sharedPromo))
    },
    handleUseTermsOrItemGainedChange(from) {
      if(this.useTerms == 'range' && this.itemGained == 'services') {
        this.warningDialog = true
        this.warningTitle = 'Attenzione'
        this.warningMessage = 'Attenzione, stai attivando una promozione con servizi gratuiti in modalità range.'
      }

      if(from == 'useTerms' && this.useTerms == 'range' || this.useTerms == 'untillEndRange') {
        this.scrollToBottom()
      }
      if (from != 'useTerms') {
        this.useTerms = undefined
      }
      this.updateObject()
    },
    scrollToBottom() {
      let elementToScroll = document.getElementsByClassName('dialog-card-text')
      if(elementToScroll.length > 0) {
        setTimeout(() => {
          elementToScroll[0].scrollBy(0, 1000)
        }, 50);
      }
    },
    addPlaceholder(name, text) {
      let placeholderToInsert = this.createPlaceholderDomElement(name, text)

      let range, html
      if(window.getSelection) {
        if(!this.isInEditableSelection()) return
        let selection = window.getSelection()

        if(selection.getRangeAt && selection.rangeCount) {
          range = selection.getRangeAt(0);
          range.collapse(false)
          range.insertNode(placeholderToInsert)
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        range.collapse(false);
        html = (placeholderToInsert.nodeType == 3) ? placeholderToInsert.data : placeholderToInsert.outerHTML;
        range.pasteHTML(html);
      }

      this.focusAndEdit()
      this.calculateTextFromHtml()
    },    
    createPlaceholderDomElement(name, text) {
      let placeholderToInsert = document.createElement('span')
      placeholderToInsert.style.borderRadius = "16px"
      placeholderToInsert.style.fontSize = "14px"
      placeholderToInsert.style.height = "32px"
      placeholderToInsert.style.backgroundColor = "#8a77e2"
      placeholderToInsert.style.borderColor = "#8a77e2"
      placeholderToInsert.style.color = "white"
      placeholderToInsert.style.padding = "5px"
      placeholderToInsert.setAttribute("contenteditable", "false")
      placeholderToInsert.setAttribute("contentdisabled", "false")
      placeholderToInsert.setAttribute("data-name", name)
      placeholderToInsert.innerHTML = text
      return placeholderToInsert
    },
    isInEditableSelection() {
      const element = this.getSelectionBoundaryElement(false)
      return !!element && (element.classList.contains('editable-message'))
    },
    getSelectionBoundaryElement(isStart) {
      let range, sel, container;
      if (document.selection) {
        range = document.selection.createRange();
        range.collapse(isStart);
        return range.parentElement();
      } else {
        sel = window.getSelection();
        if (sel.getRangeAt) {
          if (sel.rangeCount > 0) {
            range = sel.getRangeAt(0);
          }
        } else {
          // Old WebKit
          range = document.createRange();
          range.setStart(sel.anchorNode, sel.anchorOffset);
          range.setEnd(sel.focusNode, sel.focusOffset);

          // Handle the case when the selection was selected backwards (from the end to the start in the document)
          if (range.collapsed !== sel.isCollapsed) {
            range.setStart(sel.focusNode, sel.focusOffset);
            range.setEnd(sel.anchorNode, sel.anchorOffset);
          }
        }

        if (range) {
          container = range[isStart ? "startContainer" : "endContainer"];

          // Check if the container is a text node and return its parent if so
          return container.nodeType === 3 ? container.parentNode : container;
        }   
      }
    },
    calculateTextFromHtml() {
      this.notes = this.convertHtmlToText(this.$refs.editableMessage.innerHTML)
      //console.log(this.notes)
      //this.$emit('input', this.localValue)
      this.updateObject()
    },
    convertHtmlToText(html) {
      let template = this.$refs.hiddenEditableMessage
      //html = html.replaceAll('&nbsp;', '\n')
      html = html.replaceAll('<div>', '')
      html = html.replaceAll('</div>', '')
      html = html.trim()
      template.innerHTML = html

      this.selectedPlaceholders = []
      
      for(let i = 0; i < this.possiblePlaceholders.length; i += 1) {
        const name = this.possiblePlaceholders[i].name
        const spanElements = template.querySelectorAll('[data-name="' + name + '"]')

        if(spanElements.length > 0) {
          this.selectedPlaceholders.push(this.possiblePlaceholders[i])
        }

        for(let k = 0; k < spanElements.length; k += 1) {
          spanElements[k].parentNode.replaceChild(document.createTextNode('<' + name + '>'), spanElements[k])
        }
      }

      return this.parseChildNodesForValueAndLines(template)
    },
    parseChildNodesForValueAndLines(HtmlNode) {
      let newValue = '';
      let isOnFreshLine = true;

      for (let i = 0; i < HtmlNode.childNodes.length; i++) {
        const childNode = HtmlNode.childNodes[i];

        if (childNode.nodeName === 'BR') {
          newValue += '\n';
          isOnFreshLine = true;
          continue;
        }

        if (childNode.nodeName === 'DIV' && isOnFreshLine === false) {
          newValue += '\n';
        }

        isOnFreshLine = false;

        if (childNode.nodeType === 3 && childNode.textContent) {
          newValue += childNode.textContent;
        }

        newValue += this.parseChildNodesForValueAndLines(childNode);
      }

      return newValue
    },
    applyAll(type) {
      if(type == 'gained') {
        if (!this.allActive) {
          this.serviceTypesGained.map((cat) => {
            for(let i = 0; i < cat.services.length; i += 1) {
              let el = cat.services[i]
              if (this.gainedServices.filter(obj => obj.name == el.name).length === 0)
                this.gainedServices.push(el)
            }
          })
          this.allServicesGained = true
          this.allActive = true
        } else {
          this.gainedServices = []
          this.allServicesGained = false
          this.allActive = false
        }
      } else if(type == 'target') {
        if (!this.allActive) {
          this.serviceTypesTarget.map((cat) => {
            cat.services.map((el) => {
              if (this.targetServices.filter(obj => obj.name == el.name).length === 0)
                this.targetServices.push(el)
            })
          })
          this.allServicesTarget = true
          this.allActive = true
        } else {
          this.targetServices = []
          this.allServicesTarget = false
          this.allActive = false
        }
      } else if (type == 'valid') {
        if (!this.allActive) {
          for(let i = 0; i < this.serviceTypesValid.length; i += 1) {
            let cat = this.serviceTypesValid[i]
            for(let k = 0; k < cat.services.length; k += 1) {
              let el = cat.services[k]
              if (this.validDiscountServices.filter(obj => obj.id == el.id).length === 0)
                this.validDiscountServices.push(el)
            }
          }
          this.allServicesValid = true
          this.allActive = true
        } else {
          this.validDiscountServices = []
          this.allServicesValid = false
          this.allActive = false
        }
      }
      this.updateObject()
    },
    focusAndEdit() {
      let el = this.$refs.editableMessage

      //el.focus()
      if(typeof window.getSelection != "undefined" && typeof document.createRange != "undefined") {
        let range = document.createRange()
        range.selectNodeContents(el)
        range.collapse(false)
        let sel = window.getSelection()
        sel.removeAllRanges()
        sel.addRange(range)
      } else if(typeof document.body.createTexRange != "undefined") {
        var textRange = document.body.createTextRange();
        textRange.moveToElementText(el);
        textRange.collapse(false);
        textRange.select();
      }
    },
    getPrimaryColor() {
      const isDark = this.$vuetify.theme.isDark
      if(isDark) return this.$vuetify.theme.themes.dark.primary
      else return this.$vuetify.theme.themes.light.primary
    },
    convertTextToHtml(text) {
      let finalText = text

      if(!finalText) finalText = ''

      for(let i = 0; i < this.possiblePlaceholders.length; i += 1) {
        const name = this.possiblePlaceholders[i].name
        const text = this.possiblePlaceholders[i].text
        const spanElement = this.createPlaceholderDomElement(name, text).outerHTML
        finalText = finalText.replaceAll('<' + name + '>', spanElement)
      }
      
      return finalText
    },
    reset(){
      this.id = undefined
      this.name = undefined
      this.description = undefined
      this.notes = ''
      //this.notesReceipt =undefined
      this.gainType = undefined
      this.price = undefined
      this.itemGained = undefined
      this.duration = undefined
      this.amount = undefined
      this.discount = undefined
      this.useTerms = undefined
      this.rangeMode = undefined
      this.expirationDate = undefined
      this.startDate = undefined
      this.allServicesTarget = undefined
      this.allServicesGained = undefined
      this.allServicesValid = undefined
      this.allItemsValid = undefined
      this.allServicesTargetQuantity = undefined
      this.allServicesGainedQuantity = undefined
      this.localStatus = undefined
      this.paymentType = undefined
      this.activeFrom = undefined
      this.activeTo = undefined
      this.newCustomer = undefined
      this.validDiscountServices = []
      this.validDiscountServicesQuantity = []
      this.targetServices = []
      this.validServices = []
    }
  },
  computed: {
    disabled() {
      return this.type == 'edit' && this.isInUse
    },
    sharedPromo() {
      return {
        id: this.id,
        name: this.name,
        description: this.description,
        barcode: this.barcode,
        notes: this.notes,
        //notesReceipt: this.notesReceipt,
        gainType: this.gainType,
        price: this.price,
        itemGained: this.itemGained,
        duration: this.duration,
        amount: this.amount,
        discount: this.discount,
        useTerms: this.useTerms,
        rangeMode: this.rangeMode,
        expirationDate: this.expirationDate,
        startDate: this.startDate,
        allServicesTarget: this.allServicesTarget,
        allServicesGained: this.allServicesGained,
        allServicesValid: this.allServicesValid,
        allItemsValid: this.allItemsValid,
        allServicesTargetQuantity: this.allServicesTargetQuantity,
        allServicesGainedQuantity: this.allServicesGainedQuantity,
        status: this.localStatus,
        paymentType: this.paymentType,
        activeFrom: this.activeFrom,
        activeTo: this.activeTo,
        newCustomer: this.newCustomer,
        gainedItems: cloneDeep(this.validDiscountServices.map(service => {
          return {
            serviceId: service.id,
            quantity: this.validDiscountServicesQuantity[service.id] === 0 ? undefined : this.validDiscountServicesQuantity[service.id],
            name: service.name
          }
        })),
        validDiscountItems: cloneDeep(this.validDiscountServices.map(service=>{
          return { id: service.id, name: service.name, quantity: this.validDiscountServicesQuantity[service.id] }
        })),
        targetItems: cloneDeep(this.targetServices.map(service => ({
          serviceId: service.id,
          quantity: this.targetServicesQuantity[service.id]
        }))),
        validItems: cloneDeep(this.validServices.map(service => ({
          serviceId: service.id
        })))
      }
    },
    computedValid() {
      return this.formValid && (!!this.allServicesValid || !!this.allItemsValid || this.validDiscountServices.length > 0) &&
        this.serviceTypePromoQuantityValid
    },
    serviceTypePromo() {
      return this.gainType == 'price' && this.itemGained == 'discount'
    },
    serviceTypePromoQuantityValid() {
      return (this.serviceTypePromo && (Object.values(this.validDiscountServicesQuantity).every((v) => !v)
        || Object.values(this.validDiscountServicesQuantity).every((v) => !!v)) || !this.serviceTypePromo)
    },
    isUntilEndReasonable() {
      let areAllQuantityEmpty = !!this.gainedServicesQuantity && Object.values(this.gainedServicesQuantity).some((v) => !v)
      let isServicesGainedReasonable = (this.itemGained == 'services' || this.itemGained == 'discount') && !areAllQuantityEmpty
      let isItemGainedReasonable = this.itemGained == 'amount' || isServicesGainedReasonable
      return isItemGainedReasonable
    },
    filteredUseTermsItems() {
      return this.useTermsItems.filter((el) => {
        return this.isUntilEndReasonable || (el.name != 'untillEnd' && el.name != 'untillEndRange')
      })
    }
  },
  watch: {
    computedValid(newVal) {
      this.$refs.editableMessage.innerHTML = this.convertTextToHtml(this.notes)
      this.$emit('update:valid', newVal)
      if(this.bus) {
        this.bus.$emit('update:valid', newVal)
      }
      promoForm.setValid(newVal)
    },
    status(newVal) {
      this.localStatus = newVal
    },
    async id(newVal) {
      if (!!newVal) {
        let promoCustomerslist = await promosService.listPromoCustomerByPromoId({ id: newVal})
        this.isInUse = await promoCustomerslist.filter(el => el.usageProgress == 'beginning').length > 0
      }
    }
  }
};
</script>

<style scoped>
.editable-message {
  border-radius: 5px;
  white-space: normal;
  background: rgba(193, 187, 187, 0.25);
  padding: 20px 20px 20px 15px;
  overflow: auto;
  word-wrap: break-word;
}

[contentdisabled] {
  pointer-events: none;
}

.chip {
  border-radius: 16px;
  font-size: 14px;
  height: 32px;
  background-color: #8a77e2 !important;
  border-color: #8a77e2 !important;
  color: white;
}

.hidden-editable-message {
  visibility: hidden;
  height: 0px;
}

.chip {
  border-radius: 16px;
  font-size: 14px;
  height: 32px;
  background-color: #8a77e2 !important;
  border-color: #8a77e2 !important;
  color: white;
}
</style>