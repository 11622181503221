import eventsHandler from '@/services/common/eventsHandler.js'

class promoForm extends eventsHandler {
  constructor() {
    super()
    this._promo = {}
    this._valid = false
  }

  get promo() {
    const promo = {
      ...this._promo
    }
    return promo
  }

  get valid() {
    return this._valid
  }

  get isNew() {
    return !this._promo.id
  }

  setValid(valid) {
    this._valid = valid
    this._executeCallbacksOf('valid-change', valid)
  }

  resetPromo() {
    this._promo = {}
    this._executeCallbacksOf('reset')
    this._executeCallbacksOf('update', {promo: {}})
  }

  updateField(key, value) {
    this._promo[key] = value
    this._executeCallbacksOf('update', {promo: this._promo})
    this._executeCallbacksOf('update-' + key, {key: this._promo[key]})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }

  updateObject(promo) {
    this._promo = promo
    this._executeCallbacksOf('update', {promo: this._promo})
    this._executeCallbacksOf('update-is-new', this.isNew)
  }
}

export default new promoForm()